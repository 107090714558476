@import 'src/stylesheets/abstracts/abstracts';

.article {
  
  &__header {
    display: block;
    margin: 1.5rem 0;
    padding: 0 1.5rem;
    font-size: 1.5rem;
    line-height: 1.2;
    text-align: center;
    color: darkgreen($gum);

    @media (min-width: 768px) {
      font-size: 2.5rem;
      margin: 2rem 0;
      padding: 0 2rem;
    }
  }
  
  &__img {
    // border: 1px solid magenta;
    position: relative;

    img {
      border-radius: 1rem;
    }
  }
}