@import 'src/stylesheets/abstracts/abstracts';

.product__button {
  position: absolute;
  // top: 50%;
  top: 0;
  right: 0;
  margin-top: -24px;
  // margin-top: 26px;
  // padding: 18px 24px;
  padding: 12px 18px;
  font-size: 0;
  line-height: 24px;
  text-decoration: none;
  color: #ffffff;
  background-color: basegreen($gum);
  box-shadow: 0px 2px 8px 0px rgba(63, 63, 63, 0.28);
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  transition: background-color ease-in-out 300ms;

  &:hover,
  &:focus {
    background-color: darkgreen($gum);
  }

  img {
    vertical-align: middle;
    width: auto;
    // height: 24px;
    height: 20px;
  }

  span {
    display: none;
  }

  @media (min-width: 1200px) {
    font-size: 19px;
    font-size: 16px;

    span {
      display: initial;
      margin-left: 18px;
    }
  }
}
