.ModalMap {
    background-color: #fbfbfb;
    overflow: hidden;
    height: 545px;

    .close:before, .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #58c7aa;
    }

    @media (min-width: 1024px) {
        width: 1024px;
        left: calc(50% - 512px);
    }

    @media (max-width:768px){
        height: auto;
    }
}

.Map{
    height: 545px;
}