@import 'src/stylesheets/abstracts/abstracts';

.subscription {
  float: left;
  padding: 32px;
  background-image: linear-gradient(to left, #85d1b5, #8ed6bb, #97dcc0, #9fe1c6, #a8e7cc);
  border-radius: 1rem;

  &__title {
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: bold;
    color: #2f6758;
    margin: 0;
  }

  &__img {
    
    @media (max-width: 767px) {
      display: none;
    }
    
    position: relative;
    z-index: 1;
    float: left;
    width: 40%;

    img {
      position: absolute;
      left: -7px;
      top: 7px;
      width: 229px;
      height: auto;
    }
  }

  &__info {
    
    @media (min-width: 768px) {
      padding-left: 30px;
      z-index: 999;
      float: right;
      width: 60%;
    }

    p {
      color: #fff;
      line-height: 1.5;
      margin: 1rem 0;
    }

    form {
      margin-top: 20px;
      position: relative;

      input {
        border-radius: 16px 16px 0 0;
        outline: none;
        border: 0;
        padding: 16px 16px;
        font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: .85rem;
        line-height: 1.5;
        width: 100%;
        margin-bottom: 16px;
        transition: all 0.1s linear 0.1s;

        &:focus {
          box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.3);
        }

        &:invalid {
          border-bottom: 1px solid red;
        }

        &:last-of-type {
          border-radius: 0 0 16px 16px;
          margin-bottom: 24px;
        }
      }
    }
  }

  &__submit {
    cursor: pointer;
    font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border: 0;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    letter-spacing: 0.4px;
    color: #ffffff;
    background-color: #6bb895;
    margin: .5rem auto 0;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 32px;
    transition: background-color ease-in-out 300ms;

    &:hover {
      background-color: #2f6758;
    }
  }

  &__error {
    // background-color: blue;
    clear: both;
    color: red;
    font-size: 11px;
    position: absolute;
    bottom: 3.75rem;
    text-align: center;

    @media (min-width: 768px) {
      bottom: 4rem;
      text-align: left;
    }
  }
}