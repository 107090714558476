@import 'src/stylesheets/abstracts/abstracts';

.product__label {
  position: absolute;
  top: 1.375rem;
  left: 0;
  margin: 0;
  // padding: .5rem 1.25rem;
  // padding: .5rem .75rem .5rem 1.25rem;
  padding: .5rem .75rem .5rem 0rem;
  border: 0;
  border-top-right-radius: 3.5rem;
  border-bottom-right-radius: 3.5rem;
  color: grey($gum);
  background-color: #ffffff;
  font-size: .75rem;
  line-height: 1;

  > span {
    display: none;
  }

  @media (min-width: 768px) {
    padding: .5rem .75rem .5rem .75rem;

    > span {
      display: initial;
    }
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .5rem 0 1rem;
    // height: 2rem;
    height: 1rem;
  }
}