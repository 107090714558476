.tooltip {
  &__container {
    display: none;
    position: fixed;
    top: 100px;
    left: 350px;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: .75rem;
    box-shadow: 0px 0px 8px 0px rgba(63, 63, 63, 0.28);
    z-index: 9999;
  }
}