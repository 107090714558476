@import 'src/stylesheets/abstracts/abstracts';

.comments {
  clear: both;
  border-top: 1px solid #efefef;
  padding-top: 5rem;

  h2 {
    font-size: 24px;
    line-height: 1;
    color: #676767;
    margin: 0 0 48px 0;
  }

  &__error {
    clear: both;
    color: red;
    font-size: 11px;
    position: relative;
    bottom: -10rem;
    text-align: center;

    @media (min-width: 768px) {
      bottom: -5rem;
      text-align: left;
    }
  }

  &__comment {
    margin-top: 2em;

    h3 {
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      margin: 0 0 1rem 0;
      color: basegreen($gum);
      padding: 0 1rem 0 0;
      border-right: 1px solid #c7c7c7;
    }

    span {
      color: #c7c7c7;
      font-size: 1rem;
      padding: 0 0 0 1rem;
    }

    p {
      font-size: 16px;
      line-height: 1.2;
      color: grey($gum);
      margin: 0 0 2rem 0;
    }
  }

  &__create-comment {
    margin-top: 3rem;

    textarea {
      display: block;
      width: 100%;
      height: 300px;
      border-radius: 1rem 1rem 0 0;
      padding: 1rem;
      margin-bottom: 1rem;
      border: 0;
      box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);

      &:focus {
        box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.4);
        outline: none;
      }

      &.invalid {
        border-bottom: 1px solid red;
      }
    }

    input[type="text"] {
      border: 0;
      box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);
      float: left;
      width: calc(50% - .5rem);
      padding: 1rem;
      border-radius: 0 0 1rem 0;
      margin-bottom: 24px;

      &:first-of-type {
        margin-right: 1rem;
        border-radius: 0 0 0 1rem;
      }

      &:focus {
        outline: none;
        box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.4);
      }

      &.invalid {
        border-bottom: 1px solid red;
      }

      ::placeholder,
      :-ms-input-placeholder,
      ::-ms-input-placeholder {
        color: grey($gum);
        font-family: inherit;
      }
    }

    label {
      display: block;
      // clear: both;
      font-size: 14px;
      line-height: 1;
    }

/*     input[type="checkbox"] {
      margin-top: -1px;
      vertical-align: middle;
      margin: 0;
      font-size: 14px;
      line-height: 1;
    } */

    .check-content {
      display: inline-block;
    }

    .check {
      position: absolute; // take it out of document flow
      z-index: 999;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        font-size: 16px;
        line-height: normal;
      }

      // Box.
      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #c7c7c7;
      }

      // Box hover
      &:hover + label:before {
        background: #ffffff;
      }

      // Box focus
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        background: #ffffff;
      }

      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        border: 1px solid #c7c7c7;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 #6bb895,
          4px 0 0 #6bb895,
          4px -2px 0 #6bb895,
          4px -4px 0 #6bb895,
          4px -6px 0 #6bb895,
          4px -8px 0 #6bb895;
        transform: rotate(45deg);
      }
    }

    .submit {
      text-align: right;
      cursor: pointer;
      border-radius: 24px;
      font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      border: 0;
      display: block;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      text-decoration: none;
      letter-spacing: 0.4px;
      color: #ffffff;
      background-color: #6bb895;
      padding: 1rem 3rem;
      border-radius: 35px;
      transition: background-color ease-in-out 300ms;
      margin-top: 5rem;
      margin-bottom: 2rem;
      float: right;

      &:hover {
        background-color: #2f6758;
      }

      @media (min-width: 768px) {
        margin-top: 0;
      }
    }
  }
}