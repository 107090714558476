@import 'src/stylesheets/abstracts/abstracts';

.product__content {
  position: relative;
  max-width: 1280px;
  padding: 32px;
  border-radius: 1rem;
  margin: 1rem 1rem 0;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.14);

  @media (min-width: 768px) {
    padding: 40px;
  }

  @media (min-width: 1280px) {
    margin: 1rem auto 0;
  }

  &-info {
    @media (min-width: 768px) {
      float: left;
      width: 60%;
    }
  }

  &-header {
    font-size: 1.2rem;
    line-height: 1.2;
    margin: 0;
    color: grey($gum);

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &-copy {
    max-width: 600px;

    p {
      font-size: .875rem;
      line-height: 1.5;
      color: grey($gum);
      margin-bottom: 1rem;

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  &-img {

    @media (min-width: 768px) {
      float: right;
      width: 40%;
      position: relative;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border: 0;
      border-radius: 1rem;
    }
  }

  &-code {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1rem;
    line-height: 1.2;
    margin: 0;
    color: grey($gum);
    z-index: 2;
  }

  &-cta {
    cursor: pointer;
    position: absolute;
    bottom: 5.25rem;
    right: 0;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    letter-spacing: 0.4px;
    color: #ffffff;
    background-color: basegreen($gum);
    margin: 0 auto;
    padding: 1.25rem 2.5rem 1.25rem 3.25rem;
    box-shadow: 0px 2px 8px 0px rgba(63, 63, 63, 0.28);
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
    // border-radius: 35px;
    transition: background-color ease-in-out 300ms;
    border: 0;
    z-index: 2;

    &:hover {
      background-color: #2f6758;
    }
  }
}