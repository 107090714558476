@import 'src/stylesheets/abstracts/abstracts';

.article__related-articles {
/*   border-top: 1px solid #efefef; */
  
  h2 {
    font-size: 32px;
    line-height: 1.2;
    margin: 5rem 0 3rem;
    text-align: center;
    color: #676767;
  }
}