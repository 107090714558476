@import 'src/stylesheets/abstracts/abstracts';

.load__more {
  margin-top: 1rem;
  text-align: center;

  &-button {
    display: inline-block;
    margin: 0 auto;
    padding: 1.5rem 2.5rem;
    font-size: 1rem;
    line-height: 1;
    color: #ffffff;
    background-color: basegreen($gum);
    text-decoration: none;
    border-radius: 2.1875rem;
    box-shadow: 0px 2px 8px 0px rgba(63, 63, 63, 0.28);
    transition: all ease-in-out 300ms;

    &:hover {
      transform: translateY(-4px);
      background-color: darkgreen($gum);
      box-shadow: 0px 4px 16px 0px rgba(63, 63, 63, 0.56);
    }

    @media (min-width: 768px) {
      padding: 1rem 2rem;
      // font-size: 1.375rem;
      font-size: 14px;
    }
  }
}