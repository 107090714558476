.Modal {
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.5);
  padding: 0;
  // top: 5%;
  // right: 5%;
  // left: 5%;
  top: 0;
  // overflow: scroll;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  z-index: 9999;
  // width: 100%;

  @media (max-width: 599px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    top: 5%;
    border-radius: 16px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.5);
    width: 600px;
    left: calc(50% - 300px);
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  z-index: 9999;

  width: 20px;
  height: 20px;
  opacity: .9;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    left: 16px;
    height: 20px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}