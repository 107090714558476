@import 'src/stylesheets/abstracts/abstracts';

.article {

  &__wrapper {
    padding: 24px 24px 0;
    
    @media (min-width: 1024px) {
      padding: 0 128px;
    }
  }

  &__content {
    @media (min-width: 768px) {
 /*      float: left; */
      margin: 0 auto;
      width: calc(100% - 348px);
/*       margin-right: 48px; */
    }

    h2 {
      font-size: 24px;
      line-height: 1.2;
      color: grey($gum);
      margin: 0 0 36px 0;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
      color: #666666;
      margin: 0;
    }

    mark {
      display: block;
      padding: 32px;
      margin: 0;
      font-size: 22px;
      line-height: 1.5;
      background-color: transparent;
      color: #c7c7c7;

      @media (min-width: 768px) {
        padding: 64px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 40px 0;
    }

    figure {
      margin: 0;
    }
  }
}