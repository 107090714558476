.sm-social {
    display: none;

    @media (max-width:768px) {
        display: block;
    }
}

.footer {
    &__social-menu {
        width: 100%;
        height: 60px;
    }

    &__menu-list {
        max-width: 180px;
        display: block;
        margin: 0 auto;
        padding: 0;
    }

    &__menu-list li {
        display: inline-block;
        list-style: none;
        text-decoration: none;
        width: 33%;
        margin: 0;
        padding: 0;
        padding: 0.8em;
    }

    &__icon {
        display: block;
        color: #ffffff;
        text-decoration: none;
        -webkit-transition: color ease-in-out 160ms;
        transition: color ease-in-out 160ms;
    }
}