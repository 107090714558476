$spinner: #6bb895;
$background: white;
$size: 40px;

html { background-color: $background; }

.spinner {
  position: relative;
  margin: 50px auto;
  width: $size;
  height: $size;
  animation: spinner 0.75s linear infinite;
  text-align: left;
  clear: both;

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .spinner-inner-1 {
    background: linear-gradient(to right, rgba($spinner,0) 0%,rgba($spinner,0) 50%,$spinner 51%);
  }

  .spinner-inner-2 {
    background: linear-gradient(to top, rgba($background,0) 0%,$background 100%);
  }

  .spinner-inner-3 {
    $offset: $size * 0.1;
    top: $offset;
    left: $offset;
    width: $size - ($offset * 2);
    height: $size - ($offset * 2);
    background: $background;
  }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}