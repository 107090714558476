@import 'src/stylesheets/abstracts/abstracts';

.forget.login {

  .login__img {
    img {
      width: 160px;
      margin: 0 auto;
    }
  }

  form {
    position: relative;
  }

  form input.invalid {
    border-bottom: 1px solid red;
  }

  .submit {
    margin: 1rem 0 0;

  }

  .rounded {
    border-radius: 16px;
  }

  .login__error {
    color: red;
    font-size: 11px;
    position: absolute;
    bottom: 3.5rem;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.login__form {
  .reset {
    @media (min-width: 420px) {
      padding: 1rem 2.4rem;
    }
  }
}