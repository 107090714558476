@import 'src/stylesheets/abstracts/abstracts';

.product {
  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.14);
  position: relative;
  transition: transform 300ms ease-in-out;
  margin-bottom: 20px;
  
  &__info {
    // padding: 24px;
    padding: 24px 24px 16px;
    position: relative;
    // background-color: red;
  }
    
  &__footer {
    padding: 0 24px 24px;
    // background-color: coral;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0px 4px 32px 0px rgba(63, 63, 63, 0.24);
  }
  
  &:hover { transform: translateY(-4px) }
  
  &:hover::after { opacity: 1 }
}

.outstanding .product {
  &:nth-child(even) { margin-bottom: 0 }
  
  @media (min-width: 720px) {
    float: left;
    width: calc(50% - 10px);
    
    &:nth-child(odd) {
      margin-bottom: 0;
      margin-right: 10px;
    }
    
    &:nth-child(even) {
      margin-left: 10px;
    }
  }
}