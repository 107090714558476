@import 'src/stylesheets/abstracts/abstracts';

.masonry {
  &__grid {
    display: flex;
    margin-left: -20px;
    width: auto;
  }

  &__column {
    padding-left: 20px;
    background-clip: padding-box;
  }
}
