.map {
    float: left;
    width: 100%;

    &__content {
        float: left;
        width: 45%;
        padding: 32px;

        @media (max-width:768px){
            float: right;
            width: 100%;
        }
    }

    &__bajada {
        h1 {
            margin: 0 0 1rem;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.2;
            color: #2f6758;
            text-align: left;
        }
        p {
            font-size: 0.85rem;
            margin-bottom: 1em;
        }
    }

    &__inner {
        position: relative;
        float: right;
        display: inline-block;
        height: 545px;
        width: 55%;
        @media (max-width:768px){
            float: left;
            width: 100%;
            position: relative;
            height: 350px;
            overflow: hidden;
        }
    }

    &__submit {
        cursor: pointer;
        width: 35%;
        border-radius: 24px;
        font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        border: 0;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        text-decoration: none;
        letter-spacing: 0.4px;
        color: #ffffff;
        background-color: #6bb895;
        padding: 0.75rem 2rem 0.75rem 2rem;
        border-radius: 0 35px 35px 0;
        -webkit-transition: background-color ease-in-out 300ms;
        transition: background-color ease-in-out 300ms;
        margin-top: 1rem;
    }

    &__input {
        // font-family: inherit;
        display: inline-block;
        width: 65%;
        outline: none;
        border: 0;
        padding: .75rem 1rem;
        font-size: .75rem;
        line-height: 1;
        margin-bottom: 1rem;
        border-radius: 35px 0 0 35px;
        -webkit-transition: all 0.1s linear 0.1s;
        transition: all 0.1s linear 0.1s;
        box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);
        &:focus {
            box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.4)
          }
    }

    &__actualLocation {
        display: block;
        text-decoration: none;
        font-size: 0.75rem;
        margin-bottom: 1.5em;
        color: #2f6758;
        span {
            vertical-align: middle;
        }
    }

    &__iconLocation {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }

    &__contentStores {
        padding: 32px;
        border-radius: 16px;
        overflow-y: scroll;
        max-height: 300px;
        box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);

        &::-webkit-scrollbar {
            width: 1em;
        }

        &::-webkit-scrollbar-track {
            border-radius: 35px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
            box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 35px;
          background-color: #6bb895;
          outline: 1px solid slategrey;
        }
    }



    &__listStores {
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            height: 83px;
            margin-bottom: 1.5em;
            float: left;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__listDetails {
        float: left;
        width: 70%;
    }

    &__listDistance {
        position: relative;
        float: left;
        width: 30%;
        height: 100%;
        color:#333;
        p {
            position: absolute;
            width: 100%;
            top: 50%;
            margin: 0;
            transform: translateY(-50%);
            font-size: 0.75rem;
            text-align: right;
        }
    }

    &__locationTitle {
        display: block;
        margin: 0 0 .5rem;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.2;
        text-decoration: none;
        color: #2f6758;
        padding-right: 38px;
    }

    &__locationDetail {
        margin: 0;
        font-size: 0.75rem;
        line-height: 1.5em;
        color:#333;
    }
    &__locationPhone {
        margin: 0;
        font-size: 0.75rem;
        line-height: 1.5em;
        color: #2f6758;
    }
}
