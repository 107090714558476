@import 'src/stylesheets/abstracts/abstracts';

.feed {
  
  &__title {
    font-size: 1.2rem;
    line-height: 1.2;
    text-align: center;
    color: grey($gum);
    margin: 2rem 0;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  &__image-container {
    float: left;
    width: 50%;
    opacity: .9;
    transition: opacity 300ms ease-in-out;
    overflow: hidden;

    &:hover {
      opacity: 1;
    }

    @media (min-width: 600px) {
      width: 33.3%;
    }

    @media (min-width: 1024px) {
      width: 20%;
    }
  }

  &__image {
    display: block;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 300ms ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}