@function color-diff($a, $b) {
  $sat: saturation($a) - saturation($b);
  $lig: lightness($a) - lightness($b);
  $fn-sat: if($sat > 0, 'desaturate', 'saturate');
  $fn-lig: if($lig > 0, 'darken', 'lighten');

  @return (
    adjust-hue: -(hue($a) - hue($b)),
    #{$fn-sat}: abs($sat),
    #{$fn-lig}: abs($lig)
  );
}

@function palette-diff($palette) {
  $base: map-get($palette, 'base');
  $colors: map-get($palette, 'colors');
  $diffs: ();

  @each $color in $colors {
    $diffs: append($diffs, color-diff($base, $color));
  }

  @return $diffs;
}

@import 'palette';

$palette-diff: palette-diff($base-palette);

@function apply-diff($color, $diff) {
  @each $function, $value in $diff {
    $color: call($function, $color, $value);
  }

  @return $color;
}

@function create-palette($base-color) {
  $palette: ();

  @each $diff in $palette-diff {
    $palette: append($palette, apply-diff($base-color, $diff));
  }

  @return $palette;
}

$gum: create-palette(#2f6758);

@function palette($base-color) {
  $colors: create-palette($base-color);
  $keys: 'darkgreen' 'basegreen' 'green' 'lightgreen' 'darkblue' 'blue' 'lightblue' 'black' 'grey' 'purple' 'fuchsia' 'yellow'; 
  $palette: ();

  @for $i from 1 through min(length($colors), length($keys)) {
    $palette: map-merge($palette, (nth($keys, $i): nth($colors, $i)));
  }

  @return $palette;
}

$gum: palette(#2f6758);

@function darkgreen($palette) {
  @if not map-has-key($palette, 'darkgreen') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `darkgreen`.";
  }

  @return map-get($palette, 'darkgreen');
}

@function basegreen($palette) {
  @if not map-has-key($palette, 'basegreen') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `basegreen`.";
  }

  @return map-get($palette, 'basegreen');
}

@function green($palette) {
  @if not map-has-key($palette, 'green') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `green`.";
  }

  @return map-get($palette, 'green');
}

@function lightgreen($palette) {
  @if not map-has-key($palette, 'lightgreen') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `lightgreen`.";
  }

  @return map-get($palette, 'lightgreen');
}

@function darkblue($palette) {
  @if not map-has-key($palette, 'darkblue') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `darkblue`.";
  }

  @return map-get($palette, 'darkblue');
}

@function blue($palette) {
  @if not map-has-key($palette, 'blue') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `blue`.";
  }

  @return map-get($palette, 'blue');
}

@function lightblue($palette) {
  @if not map-has-key($palette, 'lightblue') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `lightblue`.";
  }

  @return map-get($palette, 'lightblue');
}

@function black($palette) {
  @if not map-has-key($palette, 'black') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `black`.";
  }

  @return map-get($palette, 'black');
}

@function grey($palette) {
  @if not map-has-key($palette, 'grey') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `grey`.";
  }

  @return map-get($palette, 'grey');
}

@function purple($palette) {
  @if not map-has-key($palette, 'purple') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `purple`.";
  }

  @return map-get($palette, 'purple');
}

@function fuchsia($palette) {
  @if not map-has-key($palette, 'fuchsia') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `fuchsia`.";
  }

  @return map-get($palette, 'fuchsia');
}

@function yellow($palette) {
  @if not map-has-key($palette, 'yellow') {
    @warn "`#{inspect($palette)}` doesn't seem to have a key named `yellow`.";
  }

  @return map-get($palette, 'yellow');
}