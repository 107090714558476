@import 'src/stylesheets/abstracts/abstracts';

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #2f6758;
}

.ModalRegistry {
  background-color: #fbfbfb;

  @media (min-width: 1024px) {
    width: 900px;
    left: calc(50% - 450px);
  }
}

.registry {
  padding: 32px;

  @media (min-width: 768px) {
    padding: 48px 32px;
  }

  &__title {
    color: #2f6758;
    font-size: 1rem;
    margin: 0 0 1rem;
    line-height: 1.2;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &__copy {
    font-size: 0.875rem;
    margin: 0 0 1rem;
    line-height: 1.5;

    @media (min-width: 768px) {
      font-size: 0.85rem;
      margin: 0 0 2rem;
    }
  }

  &__input {
    display: inline-block;
    width: 100%;
    height: 34px;
    margin: 0 0 .5rem;
    padding: 8px 16px;
    border: 0;
    font-size: 12px;
    line-height: 1;
    box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);

    &:focus {
      box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.4);
    }

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:nth-child(7) {
      border-radius: 0 0 0 8px;
    }

    &:nth-child(8) {
      border-radius: 0 0 8px 0;
    }

    &:nth-child(9) {
      box-shadow: none;
    }

    &.invalid {
      border-bottom: 1px solid red;
    }

    @media (min-width: 768px) {
      width: calc(50% - 8px);
      height: 48px;
      padding: .5rem 1rem;
      font-size: 14px;
      line-height: 1.2;
      margin: 0 0 1rem;

      &:nth-child(even) {
        float: right;
        margin-left: 8px;
      }

      &:nth-child(odd) {
        float: left;
        margin-right: 8px;
      }

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:nth-child(2) {
        border-radius: 0 8px 0 0;
      }

      &:nth-child(7) {
        border-radius: 0 0 0 8px;
      }

      &:nth-child(8) {
        border-radius: 0 0 8px 0;
      }

      &:nth-child(9) {
        box-shadow: none;
      }
    }
  }

  input[type="date"] {
    width: calc(50% - 8px);
    background: none;

    -webkit-appearance: none;

    &::-webkit-inner-spin-button {
      display: none;
      -webkit-appearance: none;
    }
  }

  input[type="radio"] {
    margin-top: -1px;
    vertical-align: middle;
    margin: 0;
  }

  &__input.radio {
    height: auto;
    padding: 0;
    box-shadow: none;
  }

  &__input label {
    display: block;
    margin-bottom: .5rem;
    font-size: 12px;
  }

  &__input label:last-of-type {
    margin-bottom: 0rem;
  }

  &__submit {
    cursor: pointer;
    width: 100%;
    border-radius: 24px;
    font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border: 0;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    letter-spacing: 0.4px;
    color: #ffffff;
    background-color: #6bb895;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 35px;
    transition: background-color ease-in-out 300ms;
    margin-top: 1rem;

    @media (min-width: 768px) {
      width: auto;
      float: right;
      margin-left: 8px;
      margin-top: 0;
    }
  }

  &__back {
    cursor: pointer;
    border: 0;
    padding: 10px 20px;
    border-radius: 35px;
    background: #6bb895;
    color: #fff;
    font-size: 11px;
    outline: none;
    margin-bottom: 10px;
  }

  &__back:hover {
    background: #2f6758;
  }

  &__error {
    clear: both;
    color: red;
    font-size: 11px;
    position: relative;
    bottom: 0;
    text-align: center;

    @media (min-width: 768px) {
      bottom: 0;
      text-align: left;
    }
  }
}

select.registry__input,
select.registry__input option {
  width: calc(50% - 8px);
  float: right;
  background: none;
}

.registry__input:focus {
  outline: none;
}
