@import 'src/stylesheets/abstracts/abstracts';

.retails {
  max-width: 600px;
  width: 100%;
  padding: 24px;
  background-color: #fbfbfb;
  // border-radius: 1rem;

  @media (min-width: 600px) {
    border-radius: 1rem;
  }

  &__title {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.2;
    color: #333333;
    padding: 0;
    margin: 20px 0;
  }

  &__logo {
    float: left;
    width: 33.3%;
    margin: 0 auto;
    padding-right: 8px;
    margin-bottom: 8px;

    @media (max-width: 767px) {
      &:nth-child(3n) {
        padding-right: 0;
      }
    }

    @media (min-width: 768px) {
      width: 25%;

      &:nth-child(4n) {
        padding-right: 0;
      }
    }

    a {
      display: block;
    }

    img {
      display: block;
      width: 100%;
      max-width: 120px;
      height: auto;
      margin: 0 auto;
      transition: transform .2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}