@import 'src/stylesheets/abstracts/abstracts';

.tooltip {
  &__modal {
    width: 12.25rem;
    color: grey($gum);
    // background-color: gold;
    // border:1px solid red;
  }

  &__title {
    font-size: .875rem;
  }

  &__items {
    text-align: center;
  }

  &__item {
    display: block;
    width: 3.75rem;
    margin: 1.25rem .5rem 0 0;
    float: left;

    &:nth-child(3n),
    &:nth-child(6n) {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      color: #636469;
    }
  }

  &__icon {
    display: block;
    width: 2rem;
    height: 2rem;
    // margin: 0 auto .25rem;
    margin: 0 auto .25rem;
    padding: 0;
    border: 0;

    &-title {
      font-size: .75rem;
      line-height: 1;
      font-weight: 300;
    }
  }
}