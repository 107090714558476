@import 'src/stylesheets/abstracts/abstracts';

.login {
  background-image: linear-gradient(to left, #81c9d8, #93d0dd, #a5d8e3, #b6dfe8, #c6e7ee);
  text-align: center;
  padding: 2rem;

  @media (min-width: 768px) {
    border-radius: 12px;
  }

  &__img {

    @media (max-width: 767px) {
      display: none;
    }

    float: left;
    width: 50%;

    img {
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      bottom: -2rem;
    }
  }

  &__buttons {

    @media (min-width: 768px) {
      float: right;
      width: 50%;
    }

    > a, button {
      display: block;
      padding: .75rem 1.5rem;
      border: none;
      border-radius: 2rem;
      margin-bottom: 1rem;
      text-decoration: none;
      font-size: .75rem;
      max-width: 220px;
      margin: 0 auto 1rem;

      @media (min-width: 768px) {
        max-width: 100%;
        margin: 0 0 1rem;
        padding: 1rem 2rem;
        font-size: 1rem
      }
    }

    > button {
      padding: .75rem 3.1rem;
      cursor: pointer;

      @media (min-width: 768px) {
        padding: 1.1rem 3.39rem;
      }
    }
  }

  &__title {
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: bold;
    margin: 0 0 1.25rem 0;

    margin-top: 2.5rem;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &__facebook {
    background-color: #4665a4;
  }

  &__google {
    background-color: #4c8bf5;
  }

  &__facebook,
  &__google {
    color: #ffffff;
  }

  &__account {
    background-color: #ffffff;
    color: black;
  }

  &__create-account {
    color: #ffffff;
    // margin: 0 0 2rem 0;
    margin: 0;
    font-size: 14px;

    a {
      text-decoration: none;
      color: #2f6758;
      text-decoration: underline;
    }
  }
}