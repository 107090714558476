.tooltip {
  // float: right;
  width: 22px;
  height: 22px;
  // position: relative;
  position: absolute;
  bottom: 8px;
  right: 16px;
  // background-color: green;

  &__button {
    appearance: none;
    border-style: none;
    padding: 0;
    position: absolute;
    content: '\2807';
    width: 6px;
    height: 6px;
    background-color: #c7c7c7;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    margin: 0;
    top: 8px;
    transition: background-color ease-in-out 300ms;
    text-indent: -31000px;
    text-decoration: none;
    cursor: pointer;

    &:before, &:after {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: inherit;
      border-radius: inherit;
    }

    &:before {
      right: -8px;
    }

    &:after {
      right: -16px;
    }
  }
}