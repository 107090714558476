// @import 'fonts';

html { height: 100%; padding: 0; margin: 0; }

body { height: 100%; padding: 0; margin: 0; overflow-x: hidden; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing:  border-box;
  -moz-box-sizing:  border-box;
  box-sizing:  border-box;
}

body {
  font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #333;
}

.product__copy p {
    font-size: .875rem;
    line-height: 1.3;
    color: #676767;
    margin: 5px;
}

a:focus,
button:focus {
  outline: none;
}