.product__rating {
  height: 16px;
  margin-top: 4px;
  line-height: 16px;

  @media (min-width: 768px) {
    margin-top: 8px;
  }

  > div {
    float: left;
  }

  &-average {
    display: block;
    float: left;
    border-left: none;
    width: calc(100% - 83px);
    height: 16px;
    font-size: 1rem;
    line-height: 16px;
    color: #79d7bd;

    &:before {
      content: "";
      margin-right: 1rem;
    }
  }
}