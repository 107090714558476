@import 'src/stylesheets/abstracts/abstracts';

.terms {
  padding: 3rem 2rem;
  max-width: 1024px;
  margin: 0 auto;
  line-height: 1.2;

  h1 {
    margin: 0 0 2rem 0;
    font-size: 1.75rem;
    font-weight: 400;
    color: darkgreen($gum);
    
    @media (min-width: 768px) {
      font-size: 2.5rem;
      text-align: center;
    }
  }

  p {
    margin: 0 0 1rem 0;
    font-size: 1rem;
    color: grey($gum);
    line-height: 1.5;
  }
  
  h2 {
    margin: 3rem 0 1rem 0;
    font-size: 1.25rem;
    font-weight: 400;
    color: basegreen($gum);
  }
  
  ul {
    padding: 0 0 0 1rem;
    margin: 0 0 1rem 0;
    line-height: 1.5;
  }

  li {
    margin: 0 0 1rem 0;
    padding: 0;
    line-height: 1.5;
  }
}