@import 'src/stylesheets/abstracts/abstracts';

.product__title {
  display: block;
  margin: 0 0 .5rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.2;
  text-decoration: none;
  color: darkgreen($gum);
  // padding-right: 60px;
  padding-right: 38px;
  
  @media (min-width: 480px) and (max-width: 680px) {
    font-size: 1rem;
  }

  @media (min-width: 1024px) {
    &.is-outstanding {
      font-size: 1.5rem;
    }
  }

  @media (min-width: 1200px) {
    &.is-outstanding {
      padding-right: 180px;
    }
  }
}