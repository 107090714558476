@import 'src/stylesheets/abstracts/abstracts';

.product__tags {
  // float: left;
  // width: calc( 100% - 1.375rem );
  line-height: 0;
  // background-color: gold;

  a {
    display: inline-block;
    margin-right: .75rem;
    margin-bottom: 4px;
    padding-right: .75rem;
    border-right: 1px solid #c7c7c7;

    &:last-child {
      padding: 0;
      border: 0;
    }
  }

  span {
    display: inline-block;
    font-size: .75rem;
    line-height: .75rem;
    letter-spacing: 0.0255rem;
    color: #c7c7c7;
    cursor: pointer;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover{
      color: basegreen($gum);
    }


  }
}