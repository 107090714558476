@import 'src/stylesheets/abstracts/abstracts';

.login__form {
  line-height: 1;
  padding: 0;
  position: relative;

  @media (min-width: 768px) {
    float: right;
    width: 50%;
  }

  input {
    border-radius: 16px 16px 0 0;
    outline: none;
    border: 0;
    padding: 12px;
    font-size: .85rem;
    line-height: 1.5;
    width: 100%;
    margin-bottom: .75rem;
    transition: all 0.1s linear 0.1s;

    &:focus {
      box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.3);
    }

    &:last-of-type {
      border-radius: 0 0 16px 16px;
      margin-bottom: .5rem;
    }

    &.invalid {
      border-bottom: 1px solid red;
    }
  }

  .submit {
    cursor: pointer;
    border: 0;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    letter-spacing: 0.4px;
    color: #ffffff;
    background-color: #6bb895;
    padding: 1rem 1.5rem;
    border-radius: 35px;
    transition: background-color ease-in-out 300ms;
    margin: 2rem auto 1rem;

    @media (min-width: 420px) {
      padding: 1rem 3rem;
    }
  }

  .submit:hover {
    background-color: #2f6758;
  }
}

.login__forget {
  color: darkgreen($gum);
  font-size: 14px;
  line-height: 1.2;
  // display: inline-block;
  display: block;
  margin-bottom: .25rem;
}

.login__create-account {
  font-size: 14px;
  line-height: 1.2;
  display: inline-block;

  a {
    display: inline;
    line-height: 1;
  }
}

.login__error {
  color: red;
  font-size: 11px;
  position: absolute;
  bottom: 7.5rem;
  left: 0;
  right: 0;
  text-align: center;
}