@import 'src/stylesheets/abstracts/abstracts';
@import 'v-swiper';

.carrousel {
  position: relative;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.14);

  &__slide {
    border-radius: 1rem;

    a {
      text-decoration: none;
    }

    @media (min-width: 1024px) {
      height: 22rem;
    }

    @media (min-width: 1280px) {
      height: 26rem;
    }

    @media (min-width: 1440px) {
      height: 33.75rem;
    }
  }

  &__img {
    background-color: basegreen($gum);
    background-size: cover;
    background-position: left center;
    height: 16.5rem;

    @media (min-width: 1024px) {
      float: left;
      width: calc(100% - 26rem);
      height: 22rem;
    }

    @media (min-width: 1280px) {
      width: calc(100% - 30rem);
      height: 26rem;
    }

    @media (min-width: 1440px) {
      width: calc(100% - 37.5rem);
      height: 33.75rem;
    }
  }

  &__info {
    padding: 1.5rem;

    @media (min-width: 1024px) {
      float: right;
      width: 26rem;
      height: 22rem;
      padding: 2rem;
    }

    @media (min-width: 1280px) {
      width: 30rem;
      height: 26rem;
      padding: 3.25rem 5rem 3.25rem 2rem;
    }

    @media (min-width: 1440px) {
      width: 37.5rem;
      height: 33.75rem;
      padding: 3.25rem;
    }
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
  }

  &__title {
    color: darkgreen($gum);
    font-size: 1.2rem;
    margin: 0 0 2rem;
    line-height: 1.2;

    @media (min-width: 1024px) {
      font-size: 1.5rem;
      margin: 0 0 1rem;
    }

    @media (min-width: 1440px) {
      font-size: 2rem;
      margin: 0 0 2rem;
    }
  }

  &__copy p {
    color: grey($gum);
    font-size: 1rem;
    margin: 0 0 2rem;
    line-height: 1.5;

    @media (min-width: 1024px) {
      font-size: .85rem;
      margin: 0 0 1rem;
    }

    @media (min-width: 1440px) {
      font-size: 1.125rem;
      margin: 0 0 3rem;
    }
  }

  &__btn {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    letter-spacing: 0.4px;
    color: #ffffff;
    background-color: basegreen($gum);
    margin: 0 auto;
    padding: 1.25rem 2.5rem 1.25rem 3.25rem;
    box-shadow: 0px 2px 8px 0px rgba(63, 63, 63, 0.28);
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    border-radius: 35px;
    transition: background-color ease-in-out 300ms;

    @media (min-width: 1024px) {
      margin-top: auto;
      font-size: 1.25rem;
      font-size: 14px;
      margin-left: -2rem;
      padding: 1rem 2rem 1rem 2rem;
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    @media (min-width: 1440px) {
      font-size: 1.25rem;
      margin-left: -3.25rem;
      padding: 1.25rem 2.5rem 1.25rem 3.5rem;
    }

    &:hover,
    &:focus {
      background-color: darkgreen($gum);
    }
  }

  &__category-label {
    position: absolute;
    top: 1.375rem;
    left: 0;
    margin: 0;
    padding: .5rem .75rem .5rem 0rem;
    border: 0;
    border-top-right-radius: 3.5rem;
    border-bottom-right-radius: 3.5rem;
    color: grey($gum);
    background-color: #ffffff;
    font-size: .75rem;
    line-height: 1;

    span {
      display: none;
    }

    @media (min-width: 768px) {
      padding: .5rem .75rem .5rem 1.25rem;

      span {
        display: inline;
      }
    }

    img {
      display: inline-block;
      vertical-align: middle;
      margin: 0 .5rem 0 1rem;
      height: 1.5rem;
    }
  }

  &__prev,
  &__next {
    background-color: #ffffff;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    box-shadow: 0px 2px 8px 0px rgba(63, 63, 63, 0.28);
    background-size: 1rem 1rem;
    appearance: none;
    border-style: none;

    @media (min-width: 1024px) {
      background-size: 1.25rem 1.25rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  &__prev {
    left: 1rem;
  }

  &__next {
    right: 1rem;
  }

  &__pagination {
    left: 1rem;
    top: 14.5rem;

    @media (min-width: 1024px) {
      display: block;
      top: auto;
      bottom: 1rem;
    }
  }
}