@import 'src/stylesheets/abstracts/abstracts';

// .close:before, .close:after {
//   position: absolute;
//   left: 15px;
//   content: ' ';
//   height: 20px;
//   width: 2px;
//   background-color: #2f6758;
// }

// .close-min {
//   position: absolute;
//   left: 7px;
//   top: 10px;
//   width: 20px;
//   height: 20px;
//   opacity: 1;
// }
// .close-min:hover {
//   opacity: 1;
// }
// .close-min:before, .close-min:after {
//   position: absolute;
//   left: 10px;
//   content: ' ';
//   height: 13px;
//   width: 3px;
//      background-color: #ffffff;
// }
// .close-min:before {
//   transform: rotate(45deg);
// }
// .close-min:after {
//   transform: rotate(-45deg);
// }

// .contactWrap {
//   width: 100%;
//   // float: left;
//   padding: 40px 30px;
//   background-color: coral;
// }

// .contactImg {
//   float: left;
//   width: 40%;

//   img {
//     position: absolute;
//     left: 110px;
//     bottom: 0;
//     width: 209px;
//   }

// }

// .contactForm {
//   float: right;
//   width: 60%;

//   h1 {
//     color:#636469;
//     font-size: 1.5rem;
//     max-width: 450px;
//     margin: 0 0 1rem;
//     line-height: 1.2;
//   }

//   p {
//     font-size: 0.85rem;
//     margin: 0 0 2rem;
//     line-height: 1.5;
//   }

//   .error {
//     background-color:#ce3155;
//     color: #ffffff;

//     &::placeholder {
//       color: #ffffff;
//     }
//   }

//   .message-error {
//     display: inline-block;
//     position: relative;
//     line-height: 1.2;
//     padding: 10px 20px 10px 32px;
//     font-size: 10px;
//     background: #ce3155;
//     color: #fff;
//     border-radius: 35px;
//     margin-right: 5px;
//     margin-bottom: 25px;
//     text-align: right;
//   }

//   form {

//     .form-row {
//       display: block;
//     }

//     .form-col {
//       display: block;
//       float: left;
//       width: 50%;
//       padding-right: 20px;
//     }

//     .form-col:last-of-type{
//       padding-right: 0;
//     }

//     .form-col:last-of-type{
//       margin-right: 0;
//     }

//     input {
//       display: block;
//       border-radius: 14px 14px 0 0;
//       outline: none;
//       border: 0;
//       padding: 15px 15px;
//       font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//       font-size: .85rem;
//       line-height: 1.5;
//       width: 100%;
//       margin-right: 20px;
//       margin-bottom: 15px;
//       transition: all 0.1s linear 0.1s;
//     }

//     input:focus {
//       box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.3);
//     }



//     textarea {
//       border-radius: 0 16px 16px 0;
//       outline: none;
//       border: 0;
//       padding: 15px 15px;
//       font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//       font-size: .85rem;
//       line-height: 1.5;
//       width: 100%;
//       height: 245px;
//       margin-bottom: 15px;
//       box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);
//     }

//     .no-border {
//       border-radius: 0;
//     }

//     .top-border {
//       border-radius: 16px 0 0 0;
//     }

//     .btm-border {
//       border-radius: 0 0 0 16px;
//     }

//     input:last-of-type {
//       margin-right: 0;
//     }

//     .tag {
//       font-size: 0.85rem;
//       margin: 0 0.9rem 0;
//       max-height: 50px;
//       display: flex;
//       align-items: center;
//       line-height: 1.5;
//     }

//     .date {
//       width: 54px;
//       margin: 0;
//       max-height: 50px;
//       margin-right: 10px;
//       flex-grow: initial;
//       text-align: center;
//     }

//     .year {
//       width: 80px;
//     }

//     .control-year {
//       display: flex;
//       margin-left: auto;
//     }
//     .select {
//       position: relative;
//       margin-bottom: 15px;
//       width: 200px;
//       margin-left: auto;
//     }

//     .foot-form {
//       align-items: center;
//       margin-top: 25px;
//     }

//     .select select {
//       font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//       font-size: .85rem;
//       line-height: 1.5;
//       display: inline-block;
//       width: 100%;
//       cursor: pointer;
//       padding: 15px 15px;
//       outline: 0;
//       border: 0px solid #000000;
//       background: #ffffff;
//       color: #333;
//       box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);
//       appearance: none;
//       -webkit-appearance: none;
//       -moz-appearance: none;
//     }

//     .select select::-ms-expand {
//       display: none;
//     }

//     .select select:disabled {
//       opacity: 0.5;
//       pointer-events: none;
//     }

//     .select_arrow {
//       position: absolute;
//       top: 17px;
//       right: 19px;
//       width: 0px;
//       height: 0px;
//       border: solid #6bb895;
//       border-width: 0 2px 2px 0;
//       display: inline-block;
//       padding: 5px;
//       transform: rotate(45deg);
//       -webkit-transform: rotate(45deg);
//       transition: background-color ease-in-out 300ms;
//     }

//     .select select:hover~.select_arrow,
//     .select select:focus~.select_arrow {
//       border-color: #2f6758;
//     }

//     .select select:disabled~.select_arrow {
//       border-top-color: #cccccc;
//     }
//     button {
//       font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
//       border: 0;
//       cursor: pointer;
//       width: 200px;
//       max-height: 50px;
//       display: block;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 1.2;
//       text-decoration: none;
//       letter-spacing: 0.4px;
//       color: #ffffff;
//       background-color: #6bb895;
//       margin-left: auto;
//       padding: 1rem 2rem 1rem 2rem;
//       border-radius: 35px;
//       transition: background-color ease-in-out 300ms;
//     }

//     button:hover {
//       background-color: #2f6758;
//     }
//   }
// }

@media (min-width: 1024px) {
  .ModalContact {
    width: 90%;
    left: 5%;
    right: 5%;
   }
}

@media (min-width: 1200px) {
  .ModalContact {
    width: 1024px;
    left: calc(50% - 512px);
   }
}

// .ModalContact {
//   background-image: url('./contacto-bg.png');
//   background-size: cover;
// }

.contact {
  font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-image: url('./contacto-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 75%;
  border-radius: 16px;
  padding: 32px 16px;

  @media (min-width: 768px) {
    padding: 40px 32px;
    width: 100%;
  }

  &__img {
    float: left;
    width: 40%;

    @media (max-width: 1023px) {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      width: 200px;
      height: auto;
      margin: 0 auto;
      position: relative;
      bottom: -1rem;
    }
  }

  &__form {
    @media (min-width: 1023px) {
      float: right;
      width: 60%;
    }
  }

  &__title {
    max-width: 450px;
    margin: 0 0 1rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    color:#636469;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 1.5rem;
      text-align: left;
    }
  }

  @media (min-width: 768px) {
    &__col:nth-child(odd) {
      float: left;
      width: calc(50% - .5rem);
      margin-right: .5rem;
    }

    &__col:nth-child(even) {
      float: right;
      width: calc(50% - .5rem);
      margin-left: .5rem;
    }
  }


  &__input {
    font-family: inherit;
    display: block;
    width: 100%;
    outline: none;
    border: 0;
    padding: .75rem 1rem;
    font-size: .75rem;
    line-height: 1;
    width: 100%;
    margin-bottom: 1rem;
    transition: all 0.1s linear 0.1s;
    border-bottom: 1px solid #ffffff;

    &:first-of-type {
      border-radius: 16px 16px 0 0;
    }

    // &:last-of-type {
    //   border-radius: 0 0 0 16px;
    // }

    &.Invalid {
      border-bottom: 1px solid red;
    }

    &:focus {
      box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.3);
    }

    @media (min-width: 768px) {
      &:first-of-type {
        border-radius: 16px 0 0 0;
      }

      &:last-of-type {
        margin-bottom: 0;
        border-radius: 0 0 0 16px;
      }

      padding: 1rem;
    }
  }

  &__textarea {
    font-family: inherit;
    display: block;
    outline: none;
    border: 0;
    padding: 15px 15px;
    font-size: .85rem;
    line-height: 1.5;
    width: 100%;
    height: auto;
    box-shadow: 0px 2px 12px 0px rgba(63, 63, 63, 0.2);
    margin-bottom: 2.5rem;
    border-radius: 0 0 16px 16px;

    &:focus {
      box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.3);
    }

    &.invalid {
      border-bottom: 1px solid red;
    }

    @media (min-width: 768px) {
      height: 234px;
      margin-bottom: 2rem;
      border-radius: 0 16px 16px 0;
    }
  }

  &__btn {
    cursor: pointer;
    clear: both;
    border: 0;
    width: 200px;
    max-height: 50px;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    text-decoration: none;
    letter-spacing: 0.4px;
    color: #ffffff;
    background-color: #6bb895;
    padding: 1rem 2rem;
    border-radius: 24px;
    transition: background-color ease-in-out 300ms;
    margin: 0 auto;

    &:hover {
      background-color: #2f6758;
    }

    @media (min-width: 768px) {
      float: right;
    }
  }

  &__error {
    clear: both;
    color: red;
    font-size: 11px;
    position: relative;
    bottom: 5rem;
    text-align: center;

    @media (min-width: 768px) {
      bottom: 4rem;
      text-align: left;
    }
  }
}