@import 'src/stylesheets/abstracts/abstracts';
@import 'hamburgers/hamburgers';

.preheader {
  background-color: #ffffff;
  color: grey($gum);
  z-index: 1;
  line-height: 0;
  margin-top: 1.5rem;

  &__container {
    box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.14);
    border-radius: 20px;
    position: relative;

    @media (min-width: 1024px) {
      border-radius: 24px;
    }

    @media (min-width: 1440px) {
      border-radius: 29px;
    }
  }

  &__logos {
    float: unset;
    width: 112px;
    height: 40px;
    position: relative;

    @media (max-width:768px){
      float: left;
    }

    @media (min-width: 1024px) {
      float: left;
      width: 130px;
      height: 48px;
    }

    @media (min-width: 1440px) {
      width: 10rem;
      height: 58px;
    }
  }

  &__sublogo {
    display: block;
    position: absolute;
    top: -12px;
    right: 0;

    @media (min-width: 1024px) {
      top: -16px;
    }

    @media (min-width: 1440px) {
      top: -20px;
    }

    img {
      display: block;
      width: 62px;
      height: auto;
      border: 0;

      @media (min-width: 1024px) {
        width: 72px;
      }

      @media (min-width: 1440px) {
        width: 88px;
      }
    }
  }

  &__logo {
    display: block;
    width: 7rem;

    @media (min-width: 1024px) {
      width: 8.125rem;
      height: 3rem;
    }

    @media (min-width: 1440px) {
      width: 10rem;
      height: 3.625rem;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border: 0;
    }
  }

  &__menus {
    @media (max-width: 1023px) {
      display: none;
      top: 10px;
/*       min-height: 100vh; */
/*       box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.14); */
      box-shadow: 0px 2px 0px 0px rgba(63, 63, 63, 0.14);

/*       &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        top: -16px;
        right: 26px;
        width: 0px;
        height: 0px;
        border-top: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #ffffff;
        border-left: 8px solid transparent;
      } */
    }

    position: relative;
    right: 0;
    z-index: 999;
    width: 100%;

    @media (min-width: 1024px) {
      padding-left: 130px;
    }

    @media (min-width: 1440px) {
      padding-left: 160px;
    }

    &.is-active {
      float: left;
      display: block;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }

  &__menu {
    @media (min-width: 1024px) {
      float: left;
      margin-left: 2rem;
    }
  }

  &__social-menu {

    @media (max-width:1023px) {
      padding: 0 0.5em 1em;
      .mobile-view {
        padding: 6px 6px;
        text-align: center;
      }
    }

    .preheader__menu-item {
      float: left;

      @media (max-width:1023px){
        display: inline-block;
        height: 34px;
      }

      @media (max-width:680px){
        display: inline-block;
        height: auto;
      }


      .icon-social {
        @media (max-width:1023px){
          padding: 0.5em;
          display: block;
        }
      }
    }

    @media (min-width: 1024px) {
      float: right;
      margin-right: 1rem;

      // .preheader__menu-item:last-child {
      .preheader__menu-item:nth-child(5) {
        height: auto;
        padding-left: 1rem;
        border-left: 1px solid grey($gum);

        .preheader__link {
          padding-right: 0;
        }
      }
    }
  }

  &__menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 0;

    @media (min-width: 1024px) {
      float: right;
      padding: 10px 0 0;
    }

    @media (min-width: 1440px) {
      padding: 15px 0 0;
    }
  }

  &__menu-item {
    padding: 0;
    margin: 0;
    position: relative;

    @media (min-width: 1024px) {
      cursor: pointer;
      height: 38px;
      display: inline-block;
    }

/*     &:hover > .preheader__dropdown,
    &:focus > .preheader__dropdown {
      position: absolute;
      opacity: 1;
      display: inline-block;
    } */
  }


  .dropdown:hover {
    .preheader__link {
      color: basegreen($gum);
    }
    .preheader__dropdown {
      position: absolute;
      opacity: 1;
      display: inline-block;
      @media (max-width:1023px){
        position: relative;
      }
    }
  }

  &__linkStores {
    vertical-align: middle;
    font-size: 14px;
    display: block;
    color: grey($gum);
    text-decoration: none;
    line-height: 28px;
    padding: 1rem 1rem 0;
    transition: color ease-in-out 160ms;

  }


  &__link {
    display: block;
    color: grey($gum);
    text-decoration: none;
    font-size: 12px;
    line-height: 28px;
    padding: 1rem;
    transition: color ease-in-out 160ms;

    @media (max-width:768px){
      padding: 0.5em;
    }

    @media (max-width:680px) {
      padding: 0.5em;
    }

    .name {
      @media (max-width:680px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 60px;
        margin-right: 5px;
      }
    }

    span {
      display: block;
      float: left;
      margin-right: 1rem;
      @media (max-width:1023px) {
        margin-right: 10px;
      }
    }

    &:hover,
    &:focus {
      color: basegreen($gum);
    }

    @media (min-width: 1024px) {
      padding: 0 1rem 0 0;
    }

    @media (min-width: 1440px) {
      font-size: 1rem;
    }
  }

  // agregarle onclick
  &__dropdown {
    font-size: 0;
    margin: 0;
    padding: 0;
    list-style: none;

    @media (min-width: 1024px) {
      display: inline-block;
      display: none;
      opacity: 0;
      position: absolute;
      top: 2.4rem;
      left: 0;
      z-index: 2;
      margin: 0;
      padding: 1rem;
      border-radius: 0 0 .5rem .5rem;
      background-color: #ffffff;
      box-shadow: inset 0 7px 9px -7px rgba(0, 0, 63, 0.14), 0px 0px 8px 0px rgba(0, 0, 63, 0.14);;
/*       box-shadow: 0px 0px 8px 0px rgba(0, 0, 63, 0.14);  */
      transition: opacity ease-in-out 300ms;
    }

    li {
      list-style: none;
      padding: 0;
      margin: 0 0 1rem 1rem;
      white-space: nowrap;
      line-height: 0;
      display: block;

      a:hover,
      a:focus {
        color: basegreen($gum);
      }

      @media (min-width: 1024px) {
        margin: 0 0 1.25rem;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &__sublink {
    text-decoration: none;
    color: grey($gum);
    font-size: 1rem;
    line-height: 1.2;
    padding: 0 0 1rem;
    margin: 0;
  }

  .mobile-icon {
    @media (max-width:1023px){
      margin-left: 0.5em;
      display: inline-block;
    }
    @media (max-width:430px){
      display: none;
    }
  }

  &__iconStores {
    vertical-align: middle;
    margin: 0;
    margin-left: 3px;
    padding: 0;
    border: 0;
    width: 1.75rem;
    height: 1.75rem;
  }

  &__icon {
    display: block;
    margin: 0;
    padding: 0;
    border: 0;
    width: 1.75rem;
    height: 1.75rem;

    &:hover path {
      transition: all ease-in-out 400ms;
    }

    &.fb:hover path {
      fill: #4665a4;
    }

    &.tw:hover path {
      fill: #3bb1dd;
    }

    &.in:hover path {
      fill: #d93175;
    }

    &.yt:hover path {
      fill: #cc181e;
    }
  }
}

.hamburger {
  line-height: 1;

  position: absolute;
  top: 0;
  right: 16px;

  &-box {
    display: block;
  }

  @media (min-width: 1024px) {
    display: none;
  }
}