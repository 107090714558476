.preheader {
    .show-mobile, .show-mobile-nav {
        display: none;
    }
    @media (max-width:768px){
        .show-desktop {
            display: none;
        }

        .hm {
            display: none;
        }

        .show-mobile-nav {
            display: block;
        }

        .show-mobile {
            display: block;
            max-width: 190px;
            float: left;
            margin: 0 auto;
            padding: 0 0 0 1em;
        }
    }
}