// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/Gotham-Light.woff2') format('woff2'),
       url('/fonts/Gotham-Light.woff') format('woff'),
       url('/fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src:
      url('/fonts/Gotham-Book.woff2') format('woff2'),
      url('/fonts/Gotham-Book.woff') format('woff'),
      url('/fonts/Gotham-Book.otf') format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: bold;
  font-display: swap;
  font-style: normal;
  src: url('/fonts/Gotham-Bold.woff2') format('woff2'),
       url('/fonts/Gotham-Bold.woff') format('woff'),
       url('/fonts/Gotham-Bold.otf') format('opentype');
}
