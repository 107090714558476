@import 'src/stylesheets/abstracts/abstracts';

.footer {
  background-color: #2f6758;
  width: 100%;
  padding: 3.5rem 2.5rem;

  @media (max-width:680px){
    padding: 0.5rem 2.5rem 1.5rem;
  }

  a {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 .5rem 0;
    color: #ffffff;
    text-decoration: none;

    @media (max-width:680px){
      text-align: center;
      margin: 0 auto;
      display: block;
    }
  }
  
  p {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 .5rem 0;
    color: #ffffff;
    text-decoration: none;
    
    @media (max-width:680px){
      text-align: center;
    }
  }

  @media (min-width: 480px) {
    p, a {
      margin: 0;
    }

    p {
      float: left;
      text-align: center;
    }
  
    a {
      float: right;
    }
  }
}