@import 'src/stylesheets/abstracts/abstracts';

.product {
  &__content-data {
    @media (min-width: 768px) {
      float: left;
      width: calc(100% - 348px);
      margin-right: 48px;
    }

    h2 {
      font-size: 24px;
      line-height: 1.2;
      color: grey($gum);
      margin: 0 0 36px 0;
    }

    h3,
    p {
      font-size: 1em;
      line-height: 1.5;
      color: #636469;
      margin: 0;
    }

    h3 {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    p {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    mark {
      display: block;
      padding: 32px;
      margin: 0;
      font-size: 22px;
      line-height: 1.5;
      background-color: transparent;
      color: #c7c7c7;

      @media (min-width: 768px) {
        padding: 64px;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 40px 0;
    }

    figure {
      margin: 0;
    }

    ul {
      padding: 0 0 0 1em;
      color: grey($gum);
      font-size: 1em;
      line-height: 1.5;
    }
  }
}