@import 'src/stylesheets/abstracts/abstracts';

.product__copy {
  height: 3.4115rem;
  overflow: hidden;
  
  p {
    font-size: .875rem;
    line-height: 1.3;
    color: #676767;
    margin: 1px 0;
  }
  
  @media (min-width: 1024px) {
    &.is-outstanding {
      font-size: 1.125rem;
    }
  }
}