@import 'src/stylesheets/abstracts/abstracts';

.success {
  width: 100%;
  height: 370px;
  padding: 40px 30px;

  background-image: url('./success-bg.png');
  background-position-x: -80px;
  background-size: cover;
  border-radius: 1rem;

  @media (min-width: 600px) {
    width: 600px;
    left: calc(50% - 300px);
  }

  &__img {
    
    @media (max-width: 767px) {
      display: none;
    }

    @media (min-width: 768px) {
      float: left;
      width: 40%;
    }

    img {
      display: block;
      width: 160px;
      height: auto;
      margin: 0 auto;
    }
  }

  &__info {
    height: 309px;

    @media (min-width: 768px) {
      float: right;
      width: 60%;
    }

    h2 {
      margin: 0 0 1rem;
      font-size: 1.5rem;
      line-height: 1.2;
      text-align: center;
      color: #636469;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      margin: 0 0 1rem;
      
      span {
        font-weight: 600;
        color: #6bb895;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }
}