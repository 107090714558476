@import 'src/stylesheets/abstracts/abstracts';

.comments-have-qualifications .comments  {
  background-color: gold;

  &__comment {

    h3 {
      color: #636469;
      border: 0;
      margin: 1rem 0 .5rem 0;
    }

    span {
      color: #79d7bd;

      &:before {
        content: "|";
        color: #c7c7c7;
      }
    }

    p {
      font-weight: 300;
    }
  }

  &__error {
    bottom: 0;
    margin: 10px 0;
    float: left;
  }

  &__create-comment {
    margin-top: 3rem;

    textarea {
      height: 120px;
      border-radius: 0 0 1rem 1rem;

      &:focus {
        box-shadow: 0px 2px 16px 0px rgba(63, 63, 63, 0.4);
      }
    }

    input[type="text"] {
      float: none;
      width: 100%;
      border-radius: 0 0 1rem 0;

      &:first-of-type {
        margin-right: 1rem;
        border-radius: 1rem 1rem 0 0;
      }
    }

    span {
      float: left;
      margin-right: 8px;
    }

    .dv-star-rating {
      float: left;
      padding-top: 5px;
    }

    > div {
      height: 18px;
      float: left;
      line-height: 18px;
    }
  }
}